import React from 'react';

import stylesPlain from './plain.module.css';

import Field from './Field';

import { getCurrentResource, getDefinedValue, getDefinedItem } from '../../util/tools';
import { IET_SET_VALUE } from '../../util/realm/instance';

class FieldEnum extends Field {
	
	enumeration;

	/*
		enumeration
		wrongValues   
		styleSelect // !!! Obsolete
		styles
			label
			select                
	*/
	constructor(props) {
		super(props);
		this.state.value = this.field ? getDefinedValue( this.field.value, '') : '';
	}
	evaluateDoInit(prevProps, prevState) {
		let doInit = super.evaluateDoInit( prevProps, prevState );
		doInit = doInit || prevProps.enumeration !== this.props.enumeration;
		return doInit;
	}
	completeComponentDidUpdate(prevProps, prevState, doInit) {
		if ( doInit ) {
			this.setState({ value: getDefinedItem( this, ['field', 'value'], '') });
		}
	}
	completeInit() {
		this.resource = getCurrentResource().components.plain.FieldEnum;
		if( this.field ) {
			if( this.props.enumeration ) {
				this.enumeration = this.props.enumeration;
			} else {
				let enumerationId = this.field.model.enumerationId;
				this.enumeration = this.props.plain.realm.getEnumeration( enumerationId );
			}
			if( this.enumeration ) {
				if( this.enumeration.items[0].value != '' ) {
					this.enumeration.items.unshift( {value: '', label: this.resource.enumNotDefined} )
				}
			}
		}
	}
	
	handleInstanceChange = async (event) => {
		if( event.eventType == IET_SET_VALUE && event.field == this.field) {
			this.setState({ value: event.newValue });
		}
	}
	handleValueChange = e => {
		let value = e.target.value;
		this.props.instance.setFieldValue( this.field, value )
	}
	isWrongValue = (valueIndex) => {
		if( ! this.props.wrongValueIndices )
			return false;
		for(let index of this.props.wrongValueIndices ) {
			if( index == valueIndex )
				return true;
		}
		return false;
	}
	render() {
		if( this.field == null ) {
			return this.createDivUnknownField();
		}
		if( ! this.enumeration )
			return ( <span>Absent enumeration with id {this.field.model.enumerationId}</span> );
					
		let optionsHtml = this.enumeration.items.map((item, index) => {
			return (<option key={index} value={item.value} title={item.description}>{item.label}</option>);
		});	

		let selectStyle = Object.assign({}, 
				getDefinedValue(this.props.styleSelect, {}), // Obsolete 
				getDefinedItem(this.props, ['styles', 'select']));
		if( this.props.wrongValues && this.props.wrongValues.includes(this.state.value) )
			selectStyle.backgroundColor = 'lightYellow';
		let disabled = getDefinedValue(this.props.readOnly, false);
		return (
			<React.Fragment>
				{this.createLabel()}
				<select className={stylesPlain.selectEnum} style={selectStyle} 
					value={this.state.value} onChange={this.handleValueChange}
					disabled={disabled} 
				>
					{optionsHtml}
				</select>
			</React.Fragment>
		)
	}
	
}

export default FieldEnum;
