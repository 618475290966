import React from 'react';
import stylesPlain from '../../components/plain/plain.module.css';

import PlainPage from '../../components/plain/PlainPage';
import LabelField from '../../components/plain/LabelField';
import FieldDate from '../../components/plain/FieldDate';
import FieldString from '../../components/plain/FieldString';
import FieldEnum from '../../components/plain/FieldEnum';
import FieldInteger from '../../components/plain/FieldInteger';
import FieldReference from '../../components/plain/FieldReference';
import FieldFile from '../../components/plain/FieldFile';
import FieldList_Enum from '../../components/plain/FieldList_Enum';
import FieldList_Reference from '../../components/plain/FieldList_Reference';
import FieldList_MultilangString from '../../components/plain/FieldList_MultilangString';
import TabbedPane from '../../components/plain/TabbedPane';
import FieldList_CardsPanel from '../../components/plain/FieldList_CardsPanel';
import FieldList_TableGroup from '../../components/plain/FieldList_TableGroup';
import ButtonSelectInstance from '../../modal/selectinstance/ButtonSelectInstance';

import TabBrDerivatives from './components/TabBrDerivatives';
import TabBrHonors from './components/TabBrHonors';
import TabBrNames from './components/TabBrNames';
import TabBrAuthors from './components/TabBrAuthors';
import TabBrPhysChar from './components/TabBrPhysChar';
import TabBrContent from './components/TabBrContent';
import TabBrIdentification from './components/TabBrIdentification';
import TabBrDocLinks from './components/TabBrDocLinks';
import TabBrTopicLinks from './components/TabBrTopicLinks';
import TabBrFiles from './components/TabBrFiles';
import TabBrImages from './components/TabBrImages';
import TabBrArrange from './components/TabBrArrange';
import DivFileChar from './components/DivFileChar';

import {getLanguage} from '../../util/localStorage';
import {getDefinedValue} from '../../util/tools';
import { PROVIDER_PATH, BOOK_PLAIN_PATH } from '../../util/routes';
import { 
	FORM_PRODUCT_PLAIN, FORM_PROVIDER_TABLE, FORM_AUTHORITY_PUBLISHER_TABLE, FORM_BOOK_TABLE 
} from '../../util/consts/forms';
import { 
	ENUMERATION_CONTRIBUTOR_TYPE, ENUMERATION_LANGUAGE, ENUMERATION_MATERIAL_TYPE, 
	ENUMERATION_SHARE_TYPE, ENUMERATION_TITLE_TYPE, 
	ENUMERATION_DERIVATIVE_TYPE, ENUMERATION_HONOR_TYPE, ENUMERATION_PAGE_TYPE,
	ENUMERATION_PRODUCT_FORM_DETAIL, ENUMERATION_PRICE_QUALIFIER,

} from '../../util/consts/common';
import { AUTHORITY_PUBLISHER_PATH } from '../../util/routes';

import insertIcon from '../../assets/icons/png24x24/insert.png';
import linkIcon from '../../assets/icons/png24x24/link.png';
import clearIcon from '../../assets/icons/png24x24/edit-clear.png';

const refProviderNodes = [
	{
		classId: 'Provider', plainFormPath: PROVIDER_PATH, 
		selectInstanceFormId: FORM_PROVIDER_TABLE,
		selectInstanceFormTitle: 'Постачальники'
	}
];
const refBookNodes = [
	{
		classId: 'Book', plainFormPath: BOOK_PLAIN_PATH, 
		selectInstanceFormId: FORM_BOOK_TABLE,
		selectInstanceFormTitle: 'Books'
	}
];
const refSupplierNodes = [
	{
		classId: 'Provider', plainFormPath: PROVIDER_PATH, 
		selectInstanceFormId: FORM_PROVIDER_TABLE,
		selectInstanceFormTitle: 'Постачальники'
	}
];
const selectBookRvContext = {
	rateView: null,
	form: 'book_table',
	formTitle: 'Книга', 
	data: null
}

class ProductPlainPage extends PlainPage {

	enumContributorType = null;
	enumLanguage = null;
	enumMaterialType = null;
	enumShareType = null;
	enumTitleType = null;
	enumDerivativeType = null;
	enumHonorType = null;
	enumPageType = null;
	enumProductFormDetail = null;
	enumPriceQualifier = null;

	constructor(props) {
		super(props);
		this.formPath = FORM_PRODUCT_PLAIN;
		this.formTitle = 'Продукт';
		this.buildInstanceCard = this.buildProductCard;
	}
	async completeInit() {
		this.enumContributorType = this.realm.getEnumeration( ENUMERATION_CONTRIBUTOR_TYPE );
		this.enumLanguage = this.realm.getEnumeration( ENUMERATION_LANGUAGE );
		this.enumMaterialType = this.realm.getEnumeration( ENUMERATION_MATERIAL_TYPE );
		this.enumShareType = this.realm.getEnumeration( ENUMERATION_SHARE_TYPE );
		this.enumTitleType = this.realm.getEnumeration( ENUMERATION_TITLE_TYPE );
		this.enumDerivativeType = this.realm.getEnumeration( ENUMERATION_DERIVATIVE_TYPE );
		this.enumHonorType = this.realm.getEnumeration( ENUMERATION_HONOR_TYPE );
		this.enumPageType = this.realm.getEnumeration( ENUMERATION_PAGE_TYPE );
		this.enumProductFormDetail = this.realm.getEnumeration( ENUMERATION_PRODUCT_FORM_DETAIL );
		this.enumPriceQualifier = this.realm.getEnumeration( ENUMERATION_PRICE_QUALIFIER );
		
		return true;
	}
	componentDidMount = async () => {
		this.init('Product')
	}

	addContributorName(currentName, contributor, prefix) {
		var currentLanguage = getLanguage();
		var name = null;
		for(let n of contributor.value.names.value) {
			if( n.value.language.value == currentLanguage && n.value.shortName.value ) {
				name = n;
				break;
			}
		}
		if( name == null && contributor.value.names.value.length > 0)
			name = contributor.value.names.value[0];
		if( name != null  && name.value.shortName.value ) {
			var pref = getDefinedValue(prefix, '');
			if( currentName )
				currentName += ', ' + name.value.shortName.value;
			else
				currentName = pref + ' ' + name.value.shortName.value;
		} 
		return currentName;
	}
	buildProductCard = (productInstance) => {
		let currentLanguage = getLanguage();
		let bookProduct = productInstance.rootField.value.bookProduct;
		let card = '';
		
		let titleArea = '';
		let titleAreaCt = ''
		for(let ttl of bookProduct.value.title.value) {
			if(ttl.value.titleType.value == 'Modern') {
				if( ttl.value.language.value == currentLanguage ) {
					titleArea = getDefinedValue(ttl.value.mainName.value, ''); 
					if( ttl.value.subName.value ) 
						titleArea += ": " + ttl.value.subName.value;
					break;
				} else if( ! titleAreaCt ) {
					titleAreaCt = getDefinedValue(ttl.value.mainName.value, '');
				}
			}
		}
		if( titleArea == '' )
			titleArea = titleAreaCt;
		let materialType = bookProduct.value.edition.value.materialType.value;
		if( materialType ) {
			let materialTypeText = this.realm.getEnumerationItemText( this.enumMaterialType, materialType);
			titleArea += ' [' + materialTypeText + ']';
		}

		let author = '', compiler = '', editor = '', translator = '', illustrator = '';
		for(let con of bookProduct.value.contributor.value) {
			let contriburorType = con.value.role.value;
			let contriburorName = this.realm.getEnumerationItemText( this.enumContributorType, contriburorType);
			if( contriburorType == 'A01' ) { // AUTHOR
				author = this.addContributorName(author, con, '');
			} else if( contriburorType == 'C01' ) { // COMPILER
				compiler = this.addContributorName(compiler, con, contriburorName);
			} else if( contriburorType == 'B01' ) { // EDITOR
				editor = this.addContributorName(editor, con, contriburorName);
			} else if( contriburorType == 'B06' ) { // TRANSLATOR
				translator = this.addContributorName(translator, con, contriburorName);
			} else if( contriburorType == 'A12' ) { // ILLUSTRATOR
				illustrator = this.addContributorName(illustrator, con, contriburorName);
			}
		}
		let respArea = author
		if( compiler )
			respArea += (respArea) ? " ; " + compiler : compiler;
		if( editor )
			respArea += (respArea)? " ; " + editor : editor;
		if( translator )
			respArea += (respArea)? " ; " + translator : translator;
		if( illustrator )
			respArea += (respArea)? " ; " + illustrator : illustrator;
		
		card += titleArea;
		if( respArea )
			card += " / " + respArea;
		return card;
	}

	createTab = (tabId) => {
		let instance = this.state.instance;
		if( tabId == 'provider' )
			return <TabProvider plain={this} instance={instance}/>;
		if( tabId == 'offers' )
			return <TabOffers plain={this} instance={instance}/>;
		if( tabId == 'confirmation' )
			return <TabConfirmation plain={this} instance={instance}/>;
		if( tabId == 'derivatives' )
			return <TabBrDerivatives plain={this} instance={instance} />;
		if( tabId == 'honors' )
			return <TabBrHonors plain={this} instance={instance} />;

		let fieldBookProduct = instance.rootField.value.bookProduct;
		if( tabId == 'names' )
			return <TabBrNames plain={this} instance={instance} parentField={fieldBookProduct} />;
		if( tabId == 'authors' )
			return <TabBrAuthors plain={this} instance={instance} parentField={fieldBookProduct} />;
		if( tabId == 'edition' )
			return <TabEdition plain={this} instance={instance} />;
		if( tabId == 'physChar' )
			return <TabBrPhysChar plain={this} instance={instance} parentField={fieldBookProduct} />;
		if( tabId == 'content' )
			return <TabBrContent plain={this} instance={instance} parentField={fieldBookProduct} />;
		if( tabId == 'identidication' )
			return <TabBrIdentification plain={this} instance={instance} parentField={fieldBookProduct} />;

		if( tabId == 'docLinks' )
			return <TabBrDocLinks plain={this} instance={instance} parentField={fieldBookProduct} />;
		if( tabId == 'topicLinks' )
			return <TabBrTopicLinks plain={this} instance={instance} parentField={fieldBookProduct} />;
		if( tabId == 'files' )
			return <TabBrFiles plain={this} instance={instance} parentField={fieldBookProduct} />;
		if( tabId == 'images' )
			return <TabBrImages plain={this} instance={instance} parentField={fieldBookProduct} />;
		if( tabId == 'arrange' )
			return <TabBrArrange plain={this} instance={instance} parentField={fieldBookProduct} />;
		return null;
	}

	renderBody() {
		let tabs = [
			{
				rowId: 'first',
				tabs: [  
					{ tabId: 'provider', tabTitle: 'Постачальник', },
					{ tabId: 'offers', tabTitle: 'Пропозиції', },
					{ tabId: 'confirmation', tabTitle: 'Супровідні документи', },
					{ tabId: 'derivatives', tabTitle: 'Екранізації', },
					{ tabId: 'honors', tabTitle: 'Відзнаки і нагороди', },
				]
			},
			{
				rowId: 'second',
				tabs: [
					{ tabId: 'names', tabTitle: 'Назви', },
					{ tabId: 'authors', tabTitle: 'Автори', },
					{ tabId: 'content', tabTitle: 'Зміст', },
					{ tabId: 'edition', tabTitle: 'Видання', },
					{ tabId: 'physChar', tabTitle: 'Фізичні характеристики', },
					{ tabId: 'identidication', tabTitle: 'Ідентифікація', },
					{ tabId: 'arrange', tabTitle: 'Упорядкування', },
				]
			},
			{
				rowId: 'third',
				tabs: [
					{ tabId: 'topicLinks', tabTitle: 'Теми класифікатора', },
					{ tabId: 'images', tabTitle: 'Зображення', },
					{ tabId: 'docLinks', tabTitle: 'Зв\'язки з документами', },
					{ tabId: 'files', tabTitle: 'Електронні копії', },
				]
			},

		];
		
		let instance = this.state.instance;
		
		return (
			<div className={stylesPlain.divPlainMain}>
				{this.renderHeader()}
				
				{/* Body */}
				<div className={stylesPlain.divPlainBody} >

					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<LabelField instance={instance} path={'/providerRef'}
							styles={{ divMain: {marginRight: '10px'}}} 
						/>
						<FieldEnum instance={instance} path={'/rstype'} plain={this} 
							ignoreTitle={true} styles={{ select: {flexGrow: '0', marginRight: '20px'}}} 
						/>
						<FieldReference instance={instance} path={'/providerRef'} plain={this} 
							refNodes={refProviderNodes} ignoreTitle={true}
							styles={{ input: {flexGrow: '1', marginRight: '20px'}}} 
						/>
					</div>

					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<FieldReference instance={instance} path={'/bookRef'} plain={this} 
							refNodes={refBookNodes} readOnly={true}
							styles={{ input: {flexGrow: '1'}}} 
						/>

						{/*}	
						<div style={{display:'flex', marginLeft: '10px', minWidth: '120px'}}>
							<ButtonSelectInstance
								tooltip={'Пов\'язати з книгою і скопіювати поля'} icon={insertIcon}
								selectInstanceRvContext={selectBookRvContext} 
								onInstanceSelected={this.onInstanceSelected}
								style={{width: '36px', height: '30px', marginLeft: '4px'}}
							/>
							<ButtonSelectInstance
								tooltip={'Пов\'язати з книгою'} icon={linkIcon}
								selectInstanceRvContext={selectBookRvContext} 
								onInstanceSelected={this.onLinkInstanceSelected}
								style={{width: '36px', height: '30px', marginLeft: '4px'}}
							/>
							<button 
								onClick={this.clearBookRef}
								tooltip={'Очистити посилання'}
								style={{width: '36px', height: '30px', marginLeft: '4px'}}
							>
								<img src={clearIcon} />
							</button>
						</div>
						*/}
					</div>

					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<FieldEnum instance={instance} path={'/pubStatus'} plain={this} 
							styles={{ select: {flexGrow: '1', marginRight: '20px'}}} 
						/>
						<FieldDate instance={instance} path={'/bcudate'} plain={this} 
							styles={{ input: {flexGrow: '1', marginRight: '20px'}}} 
						/>
						<FieldEnum instance={instance} path={'/productState'} plain={this} 
							styles={{ select: {flexGrow: '1'}}} 
						/>
					</div>

					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<FieldEnum instance={instance} path={'/productForm'} plain={this} 
							styles={{ select: {flexGrow: '1', marginRight: '20px'}}} 
						/>
						<FieldEnum instance={instance} path={'/litType'} plain={this} 
							styles={{ select: {flexGrow: '1'}}} 
						/>
					</div>

					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<FieldString instance={instance} path={'/uri'} plain={this}
							styles={{ divMain: {flexGrow: '1'} }}
						/>
					</div>
					
					<TabbedPane tabs={tabs} createTab={this.createTab} 
						styles={{ divMain: {border: '1px solid gray', marginTop: '10px'} }} 
					/>
				</div>
			</div>
		)
	}
}

class TabProvider extends React.Component {
	
	constructor(props) {
		super(props)
	}
	
	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>

				<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
					<FieldReference instance={instance} path={'/supplierRef'} plain={this} 
						refNodes={refSupplierNodes}
						styles={{ input: {flexGrow: '1'}}} 
					/>
				</div>

				<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
					<FieldString instance={instance} path={'/number'} plain={plain}
						styles={{ input: {flexGrow: '1', marginRight: '20px'} }}
					/>
					<FieldEnum instance={instance} path={'/supplierRole'} plain={plain} 
						styles={{ select: {flexGrow: '1'}}} 
					/>
				</div>

				<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
					<FieldEnum instance={instance} path={'/availability'} plain={plain} 
						styles={{ select: {flexGrow: '1', marginRight: '20px'}}} 
					/>
					<FieldInteger instance={instance} path={'/instock'} plain={plain}
						styles={{ input: {flexGrow: '1', maxWidth: '70px', marginRight: '20px'} }}
					/>
					<FieldInteger instance={instance} path={'/packQty'} plain={plain}
						styles={{ input: {flexGrow: '1', maxWidth: '70px', marginRight: '20px'} }}
					/>
					<FieldDate instance={instance} path={'/startDate'} plain={plain} 
						styles={{ input: {flexGrow: '1', marginRight: '20px'}}} 
					/>
					<FieldDate instance={instance} path={'/stopDate'} plain={plain} 
						styles={{ input: {flexGrow: '1'}}} 
					/>
				</div>

			</div>
		)
	}
}
class TabOffers extends React.Component {
	
	constructor(props) {
		super(props)
	}
	
	offerCardBuilder = (fieldOffer, index) => {
		let plain = this.props.plain;
		let card = '';

		let priceQualifier = fieldOffer.value.priceQualifier.value;
		console.log('priceQualifier', priceQualifier);
		let priceQualifierText = null;
		if( priceQualifier ) {
			priceQualifierText = plain.realm.getEnumerationItemText( plain.enumPriceQualifier, priceQualifier);
			console.log('priceQualifierText', priceQualifierText, plain.enumPriceQualifier);
			card += priceQualifierText;
			console.log('card', card);
		}
		
		let price = fieldOffer.value.price.value;
		if( price ) {
			if( card )
				card += ' - ';
			card += price;
		}
		
		return card;
	}
	buildOfferPanel = (fieldGroup, listItemInfo) => {
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', backgroundColor: 'lightGray'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>

					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'state'} parentField={fieldGroup} plain={plain} 
							styles={{ select: {flexGrow: '1', marginRight: '20px'}}} 
						/>
						<FieldEnum instance={instance} path={'priceQualifier'} parentField={fieldGroup} plain={plain} 
							styles={{
								label: { divMain: {maxWidth: '130px'}}, 
								select: {flexGrow: '1', marginRight: '20px'}
							}} 
						/>
						<FieldString instance={instance} path={'delivery'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'} }}
						/>
					</div>

					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'priceType'} parentField={fieldGroup} plain={plain} 
							styles={{ select: {flexGrow: '1', marginRight: '20px'}}} 
						/>
						<FieldString instance={instance} path={'price'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1', marginRight: '20px'} }}
						/>
						<FieldString instance={instance} path={'currencyCode'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'} }}
						/>
					</div>

					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'minOrder'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1', marginRight: '20px'} }}
						/>
						<FieldDate instance={instance} path={'startDate'} parentField={fieldGroup} plain={plain} 
							styles={{ input: {flexGrow: '1', marginRight: '20px'}}} 
						/>
						<FieldDate instance={instance} path={'stopDate'} parentField={fieldGroup} plain={plain} 
							styles={{ input: {flexGrow: '1'}}} 
						/>
					</div>

					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldList_Enum
							instance={instance} path={'epubTP'} parentField={fieldGroup} plain={plain}
							labelEmptyToHeader={true}
							styles={{divMain: {flexGrow: '1'}}}
						/>
					</div>
				</div>
			</div>
		)
	}
	
	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<FieldList_CardsPanel path={'/offers'} instance={instance} plain={plain} 
					buildItemComponent={this.buildOfferPanel} itemCardBuilder={this.offerCardBuilder}
					ignoreTitle={true} labelEmptyToHeader={true}
					styles={{
						divCardsBlock: {
							marginRight: '10px',
							backgroundColor: 'rgb(240,240,240)',
							minWidth: '200px'
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'lightGray',
							minHeight: '18px'
						}
					}}
				/>
			</div>
		)
	}
}
class TabConfirmation extends React.Component {
	
	constructor(props) {
		super(props)
	}
	
	confirmationCardBuilder = (fieldConfirmation, index) => {
		let card = '';

		let name = fieldConfirmation.value.name.value;
		if( name ) {
			card += name;
		}
		
		return card;
	}
	fileCardBuilder = (fieldFileLink, index) => {
		let card = '';
		
		let typeText = fieldFileLink.value.resourceType.value;
		if( typeText )
			card += '[' + typeText + ']; ';
		
		if( fieldFileLink.value.resourceName.value )
			card += fieldFileLink.value.resourceName.value;
			
		if( card.length > 40 )
			card = card.substring(0, 37) + '...'
		return card;
	}
	buildConfirmationPanel = (fieldGroup, listItemInfo) => {
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', backgroundColor: 'lightGray'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>

					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'name'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'} }}
						/>
					</div>
					<div style={{marginBottom: '15px'}}>
						<FieldList_CardsPanel path={'images'} instance={instance} parentField={fieldGroup} plain={plain}
							labelEmptyToHeader={true}  ignoreTitle={false}
							itemComponent={ConfirmationFilePanel} itemCardBuilder={this.fileCardBuilder}
							styles={{
								divCardsBlock: {
									marginRight: '10px',
									backgroundColor: 'lightGray',
									minWidth: '200px',
									padding: '0'
								},
								divCard: {
									marginTop: '3px',
									padding: '7px',
									cursor: 'pointer',
									backgroundColor: 'lightGray',
									minHeight: '18px'
								}
							}}
						/>
					</div>

					<LabelField instance={instance} path={'comment'} parentField={fieldGroup} />
					<div style={{display: 'flex', flexGrow: '1'}}>
						<FieldString instance={instance} path={'comment'} parentField={fieldGroup} plain={plain}
							multiline={true} rows={4} cols={60}
							styleInput={{flexGrow: '1'}} ignoreTitle={true}
						 />
					</div>
				</div>
			</div>
		);
	}
	
	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{padding: '5px' }}>
				<FieldList_CardsPanel path={'/confirmations'} instance={instance} plain={plain} 
					buildItemComponent={this.buildConfirmationPanel} itemCardBuilder={this.confirmationCardBuilder}
					ignoreTitle={true} labelEmptyToHeader={true}
					styles={{
						divCardsBlock: {
							marginRight: '3px',
							backgroundColor: 'rgb(240,240,240)',
							minWidth: '200px', maxWidth: '200px'
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'lightGray',
							minHeight: '18px'
						}
					}}
				/>
			</div>
		)
	}
}
class ConfirmationFilePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		let fieldGroup = this.props.fieldGroup;
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', backgroundColor: 'rgb(230, 230, 230)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'resourceType'} parentField={fieldGroup} plain={plain}
							styleInput={{width: '150px', marginRight: '20px'}}
						 />
						<FieldString instance={instance} path={'resourceName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<DivFileChar instance={instance} fieldGroup={fieldGroup} plain={plain}
						styles={{ divMain: {marginBottom: '15px'} }} 
					/>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldFile instance={instance} path={'resourceFile'} parentField={fieldGroup} plain={plain}
							styleDiv={{flexGrow: '1'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

class TabEdition extends React.Component {

	constructor(props) {
		super(props)
	}
	
	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		let parentField = instance.rootField.value.bookProduct.value.edition;
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				
				<div style={{display: 'flex', marginBottom: '15px'}}>
					<FieldList_MultilangString
						instance={instance} path={'name'} parentField={parentField} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1'}}}
					/>
				</div>
				<div style={{display: 'flex', marginBottom: '15px'}}>
					
					<div style={{minWidth: '500px', flexGrow: '1', padding: '10px', border: '1px solid  blue', marginRight: '15px'}}>
						<FieldList_MultilangString
							instance={instance} path={'editionStatement'} parentField={parentField} plain={plain}
							labelEmptyToHeader={true}
							styles={{divMain: {flexGrow: '1', marginBottom: '15px'}}}
						/>
						
						<div style={{display: 'flex', marginBottom: '15px', alignItems: 'center'}}>
							<FieldEnum instance={instance} path={'year'} parentField={parentField} plain={plain} 
								styles={{ select: {flexGrow: '1', marginRight: '20px'}}} 
							/>
							<FieldEnum instance={instance} path={'quarter'} parentField={parentField} plain={plain} 
								styles={{ select: {flexGrow: '1', marginRight: '20px'}}} 
							/>
							<FieldEnum instance={instance} path={'month'} parentField={parentField} plain={plain} 
								styles={{ select: {flexGrow: '1'}}} 
							/>
						</div>

						<div style={{display: 'flex', marginBottom: '15px', alignItems: 'center'}}>
							<FieldEnum instance={instance} path={'materialType'} parentField={parentField} plain={plain} 
								styles={{ select: {flexGrow: '1', marginRight: '20px'}}} 
							/>
							<FieldInteger instance={instance} path={'circulation'} parentField={parentField} plain={plain}
								styles={{ input: {flexGrow: '0'} }}
							/>
						</div>

						<FieldList_MultilangString
							instance={instance} path={'material'} parentField={parentField} plain={plain}
							labelEmptyToHeader={true}
							styles={{divMain: {flexGrow: '1', marginBottom: '15px'}}}
						/>
					</div>
					
					<div style={{minWidth: '400px', flexGrow: '1', padding: '10px', border: '1px solid  blue'}}>

						<div style={{marginBottom: '15px'}}>
							<FieldList_Enum
								instance={instance} path={'editionType'} parentField={parentField} plain={plain}
								labelEmptyToHeader={true}
								styles={{divMain: {flexGrow: '1'}}}
							/>
						</div>
						
						<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
							<FieldEnum instance={instance} path={'country'} parentField={parentField} plain={plain} 
								styles={{ select: {flexGrow: '1'}}} 
							/>
						</div>

						<FieldList_MultilangString
							instance={instance} path={'place'} parentField={parentField} plain={plain}
							labelEmptyToHeader={true}
							styles={{divMain: {flexGrow: '1', marginBottom: '15px'}}}
						/>
						
					</div>
				</div>
				
				<div style={{display: 'flex', marginBottom: '15px'}}>
					<div style={{flexGrow: '1'}}>
						<FieldList_TableGroup instance={instance} path={'editionSpecial'} parentField={parentField} 
							plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter}
							table={tableEditionSpecial} cell={EditionSpecialCell}
						/>
						<FieldList_TableGroup instance={instance} path={'editionSpecial'} parentField={parentField}
							plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter} 
							table={{
								columns: [
									{ columnId: 'language', styles: {tdHeader: {width: '200px'}} },
									{ columnId: 'type', styles: {tdHeader: {width: '200px'}} },
									{ columnId: 'value' },
								],
							}}
							styles={{
								table: {width: '100%'},
								trHeader: {height: '30px'}
							}}
						/>
					</div>
				</div>

				<FieldList_MultilangString
					instance={instance} path={'comment'} parentField={parentField} plain={plain}
					labelEmptyToHeader={true}
					table={{width: '100%', styles: {}}}
				/>

				<FieldList_Reference path={'publisherAuthority'} instance={instance} parentField={parentField} plain={plain} 
					styles={{divMain: {marginBottom: '10px'}}}
					labelEmptyToHeader={true}
					refNodes={refEditionAuthorityNodes}
				/>
			</div>
		)
	}
}

const tableEditionSpecial = {
	columns: [
		{ columnId: 'language', title: 'Мова', tdStyle: {width: '200px'}, tdHeaderStyle: {width: '200px'}},
		{ columnId: 'type', title: 'Тип', tdStyle: {width: '200px'}, tdHeaderStyle: {width: '200px'}},
		{ columnId: 'value', title: 'Значення'},
	],
	styles: {
		thead: {textAlign: 'center', fontWeight: 'bold'},
		trHeader: {height: '30px'}
	}
}
const refEditionAuthorityNodes = [
	{
		classId: 'AuthorityPublisher', plainFormPath: AUTHORITY_PUBLISHER_PATH, 
		selectInstanceFormId: FORM_AUTHORITY_PUBLISHER_TABLE,
		selectInstanceFormTitle: 'Авторитетні записи'
	}
];


class EditionSpecialCell extends React.Component {

	/*
		plainDirtyCounter
		instance
		columnId
		rowIndex
		plain
		fieldGroup
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var columnId = this.props.columnId;
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;

		if( columnId == 'language' ) {
			return ( 
				<FieldEnum instance={this.props.instance} path={'language'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styleSelect={{width: '190px'}} />
			);
		}
		if( columnId == 'type' ) {
			return ( 
				<FieldEnum instance={this.props.instance} path={'type'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styleSelect={{width: '190px'}} />
			);
		}
		if( columnId == 'value' ) {
			return ( 
				<FieldString instance={this.props.instance} path={'value'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styleInput={{width: '98%'}} />
			);
		}
		return null;
	}

}

export default ProductPlainPage;
