import React from 'react';

import FieldList_TableGroup from '../../../components/plain/FieldList_TableGroup';

class TabBrIdentification extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		let parentField = this.props.parentField;
		if( ! parentField ) {
			parentField = instance.rootField;
		}
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<FieldList_TableGroup instance={instance} path={'identification'} parentField={parentField}
					plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter} ignoreTitle={true} 
					table={{
						columns: [
							{ columnId: 'type', styles: {tdHeader: {width: '10%'}} }, 
							{ columnId: 'number', styles: {tdHeader: {width: '20%'}} },
							{ columnId: 'version', styles: {tdHeader: {width: '20%'}} },
							{ columnId: 'condition', styles: {tdHeader: {width: '20%'}} },
							{ columnId: 'qualification', styles: {tdHeader: {width: '15%'}} },
							{ columnId: 'place', styles: {tdHeader: {width: '15%'} } },
						],
					}}
					styles={{
						table: {width: '100%'},
						trHeader: {height: '43px'}
					}}
				/>
			</div>
		);
	}
}

export default TabBrIdentification;