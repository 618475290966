import React, {createRef} from 'react';
import stylesPlain from '../../components/plain/plain.module.css';

import PlainPage from '../../components/plain/PlainPage';
import Label from '../../components/plain/Label';
import FieldString from '../../components/plain/FieldString';
import FieldEnum from '../../components/plain/FieldEnum';
import FieldDate from '../../components/plain/FieldDate';
import FieldImage from '../../components/plain/FieldImage';
import FieldList_CardsPanel from '../../components/plain/FieldList_CardsPanel';
import DivFileChar from './components/DivFileChar';

import {FORM_AUTHORITY_AUTHOR_PLAIN} from '../../util/consts/forms';
import { 
	ENUMERATION_LANGUAGE,
} from '../../util/consts/common';

class AuthorityAuthorPlainPage extends PlainPage {

	enumLanguage = null;

	constructor(props) {
		super(props);
		this.formPath = FORM_AUTHORITY_AUTHOR_PLAIN;
		this.formTitle = 'Автор';
		this.buildInstanceCard = this.buildAuthorCard;
	}
	async completeInit() {
		this.enumLanguage = this.realm.getEnumeration( ENUMERATION_LANGUAGE );
		
		return true;
	}
	componentDidMount = async () => {
		this.init('AuthorityAuthor')
	}

	buildAuthorCard = (authorInstance) => {
		let card = 'Author';
		return card;
	}

	nameCardBuilder = (fieldName, index) => {
		let card = '';
		let language = fieldName.value.language.value;
		if( language ) {
			let languageText = this.realm.getEnumerationItemText( this.enumLanguage, language);
			card += languageText + '; ';
		}
		if( fieldName.value.shortName.value )
			card += fieldName.value.shortName.value;
		else if( fieldName.value.fullName.value )
			card += fieldName.value.fullName.value;
		return card;
	}
	imageCardHtmlBuilder = (fieldImage, index) => {
		let card = this.imageCardBuilder(fieldImage, index);
		let image = fieldImage.value.resourceFile.value;
		let imageHtml = null;
		if( image ) {
			if( image.imageBinary )
				imageHtml = ( <img src={image.imageBinary} style={{width: '100%'}}/> );
			else if( image.fileURL )
				imageHtml = ( <img src={image.fileURL} style={{width: '100%'}}/> );
		} 
		if( imageHtml == null && fieldImage.value.resourceURI.value ) {
			imageHtml = ( <img src={fieldImage.value.resourceURI.value} style={{width: '100%'}}/> );
		}
		if( imageHtml == null ) {
			imageHtml = (<div style={{width: '100%', height: '50px', border: '1px solid black', backgroundColor: 'darkGray'}}/>)
		}
		return (
			<div style={{display: 'flex'}}>
				<div style={{width: '100px', marginRight: '10px'}}>
					{imageHtml}
				</div>
				<div>
					{card}
				</div>
			</div>
		);
	}
	imageCardBuilder = (fieldImage, index) => {
		let card = '';
		
		if( fieldImage.value.resourceName.value )
			card += fieldImage.value.resourceName.value;
			
		if( card.length > 40 )
			card = card.substring(0, 37) + '...'
		return card;
	}
	isImageCardBlockDirty = (event) => {
		let instance = this.state.instance;
		let fieldImages = instance.getField('/image');
		let f = event.field;
		
		let dirty = false;
		dirty = dirty || f.model.fieldId == 'resourceType';
		dirty = dirty || f.model.fieldId == 'resourceName';
		dirty = dirty || f.model.fieldId == 'resourceURI';
		dirty = dirty || f.model.fieldId == 'resourceFile';
		dirty = dirty && f.parent && f.parent.parent == fieldImages;
		return dirty;
	}

	buildNamePanel = (fieldGroup, listItemInfo) => {
		let instance = this.state.instance;
		return (
			<NamePanel fieldGroup={fieldGroup} listItemInfo={listItemInfo} 
				instance={instance} plain={this} 
			/>
		);
	}
	buildImagePanel = (fieldGroup, listItemInfo) => {
		let instance = this.state.instance;
		return (
			<ImagePanel fieldGroup={fieldGroup} listItemInfo={listItemInfo} 
				instance={instance} plain={this} 
			/>
		);
	}

	renderBody() {
		let instance = this.state.instance;
		return (
			<div className={stylesPlain.divPlainMain}>
				{this.renderHeader()}
				
				{/* Body */}
				<div className={stylesPlain.divPlainBody} >
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<Label title={'Ідентифікатор УІК'} tooltip={'Ідентифікатор УІК'} styles={{divMain: {marginRight: '10px'}}} />
						<FieldString instance={instance} path={'/globalId'} plain={this} 
							styles={{ input: {minWidth: '250px', flexGrow: '1', marginRight: '20px'} }}
						/>
						<FieldEnum instance={instance} path={'/subjectType'} plain={this} 
							styles={{ select: {flexGrow: '1', marginRight: '20px'}}} 
						/>
						<Label title={'Дати життя'} tooltip={'Дати життя автора'} styles={{divMain: {marginRight: '10px'}}} />
						<FieldDate instance={instance} path={'/startDate'} plain={this} 
							ignoreTitle={true} styles={{ input: {flexGrow: '1', marginRight: '20px'} }}
						/>
						<FieldDate instance={instance} path={'/stopDate'} plain={this} 
							ignoreTitle={true} styles={{ input: {flexGrow: '1', marginRight: '20px'} }}
						/>
						<FieldString instance={instance} path={'/dates'} plain={this} 
							ignoreTitle={true} styles={{ input: {flexGrow: '1', width: '100px', marginRight: '20px'} }}
						/>
					</div>
					
					<FieldList_CardsPanel path={'/name'} instance={instance} plain={this} 
						buildItemComponent={this.buildNamePanel} itemCardBuilder={this.nameCardBuilder}
						styles={{
							divMain: {
								padding: '10px 10px 10px 10px',
								backgroundColor: 'rgb(200,200,200)',
								border: '1px solid blue',
								marginTop: '10px',
								marginBottom: '10px'
							},
							divCardsBlock: {
								backgroundColor: 'rgb(200,200,200)',
								minWidth: '300px'
							},
							divCard: {
								marginTop: '3px',
								padding: '7px',
								cursor: 'pointer',
								backgroundColor: 'rgb(230,230,230)',
								minHeight: '18px'
							}
						}}
					/>

					<FieldList_CardsPanel path={'/image'} instance={instance} plain={this}
						buildItemComponent={this.buildImagePanel} isCardBlockDirty={this.isImageCardBlockDirty}
						itemCardBuilder={this.imageCardBuilder} itemCardHtmlBuilder={this.imageCardHtmlBuilder}
						styles={{
							divCardsBlock: {
								marginRight: '10px',
								backgroundColor: 'rgb(240,240,240)',
								minWidth: '500px'
							},
							divCard: {
								marginTop: '3px',
								padding: '7px',
								cursor: 'pointer',
								backgroundColor: 'lightGray',
								minHeight: '18px'
							}
						}}
					/>
				</div>
			</div>
		)
	}
}

class NamePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}

	render() {
		let fieldGroup = this.props.fieldGroup;
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '5px 0 0 0', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styles={{ select: {flexGrow: '1', marginRight: '20px'}}}
						/>
						<FieldEnum instance={instance} path={'nameType'} parentField={fieldGroup} plain={plain}
							styles={{ select: {flexGrow: '1'}}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'keyName'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'name'} parentField={fieldGroup} plain={plain}
							styles={{ input: {width: '150px', flexGrow: '1', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'secondName'} parentField={fieldGroup} plain={plain}
							styles={{ input: {width: '150px', flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'shortName'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'fullName'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'place'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'affilation'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'comment'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

class ImagePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		let fieldGroup = this.props.fieldGroup;
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', backgroundColor: 'lightGray'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'resourceName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<DivFileChar instance={instance} fieldGroup={fieldGroup} plain={plain}
						styles={{ divMain: {marginBottom: '15px'} }} 
					/>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldImage instance={instance} path={'resourceFile'} parentField={fieldGroup} plain={plain}
							styleDiv={{flexGrow: '1'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

export default AuthorityAuthorPlainPage;
