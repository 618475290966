import React from 'react';

import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldInteger from '../../../components/plain/FieldInteger';
import FieldDate from '../../../components/plain/FieldDate';
import FieldList_MultilangString from '../../../components/plain/FieldList_MultilangString';
import FieldList_Enum from '../../../components/plain/FieldList_Enum';
import FieldList_Reference from '../../../components/plain/FieldList_Reference';
import FieldList_TableGroup from '../../../components/plain/FieldList_TableGroup';
import FieldList_CardsPanel from '../../../components/plain/FieldList_CardsPanel';

import {getLanguage} from '../../../util/localStorage';
import { AUTHORITY_PUBLISHER_PATH } from '../../../util/routes';
import { FORM_AUTHORITY_PUBLISHER_TABLE } from '../../../util/consts/forms';

class TabBrEdition extends React.Component {
	
	constructor(props) {
		super(props);
	}

	editionStageCardBuilder = (fieldEditionStage, index) => {
		var plain = this.props.plain;
		var card = '';
		var currentLanguage = getLanguage();
		var name = null;
		for(let n of fieldEditionStage.value.name.value) {
			if( n.value.lang == currentLanguage && n.value.value ) {
				name = n;
				break;
			}
		}
		if( name == null && fieldEditionStage.value.name.value.length > 0)
			name = fieldEditionStage.value.name.value[0];
		if( name ) {
			card += name.value.value;
		}

		if( card ) {
			var shareType = fieldEditionStage.value.stage.value;
			var shareTypeText = plain.realm.getEnumerationItemText( plain.enumShareType, shareType);
			if( shareTypeText )
				card += ' (' + shareTypeText + ')';
		}
		
		return card;
	}
	
	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
					<FieldEnum instance={instance} path={'/edition/productForm'} plain={plain}
						styleSelect={{flexGrow: '1', marginRight: '20px'}}
					/>
					<FieldEnum instance={instance} path={'/edition/materialType'} plain={plain}
						styleSelect={{flexGrow: '1', marginRight: '20px'}}
					/>
					<FieldString instance={instance} path={'/edition/index'} plain={plain}
						styleInput={{flexGrow: '1'}}
					 />
				</div>
				<div style={{display: 'flex', marginBottom: '15px'}}>
					<FieldList_MultilangString
						instance={instance} path={'/edition/editionStatement'} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1', marginRight: '10px'}}}
					/>
					<FieldList_MultilangString
						instance={instance} path={'/edition/material'} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1', marginLeft: '10px'}}}
					/>
				</div>
				<div style={{display: 'flex', marginBottom: '15px'}}>
					<FieldList_Enum
						instance={instance} path={'/edition/editionType'} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '0', marginRight: '30px'}}}
					/>
					<div style={{flexGrow: '1'}}>
						<FieldList_TableGroup instance={instance} path={'/edition/editionSpecial'}
							plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter} 
							table={{
								columns: [
									{ columnId: 'language', styles: {tdHeader: {width: '20%'}} },
									{ columnId: 'type', styles: {tdHeader: {width: '20%'}} },
									{ columnId: 'value', styles: {tdHeader: {width: '60%'}} },
								],
							}}
							styles={{
								table: {width: '100%'},
								trHeader: {height: '40px'}
							}}
						/>
					</div>
				</div>
				<FieldList_MultilangString
					instance={instance} path={'/edition/comment'} plain={plain}
					labelEmptyToHeader={true}
					table={{width: '100%', styles: {}}}
				/>
				<div style={{marginTop: '10px', backgroundColor: 'rgb(200,200,200)', padding: '10px'}}>
					<FieldList_CardsPanel path={'/edition/editionStage'} instance={instance} plain={plain} 
						itemComponent={EditionStagePanel} itemCardBuilder={this.editionStageCardBuilder}
						styles={{
							divMainStyle: {
								padding: '10px 10px 10px 10px',
								backgroundColor: 'rgb(200,200,200)',
								border: '1px solid blue'
							},
							divCardsBlock: {
								backgroundColor: 'rgb(200,200,200)',
								minWidth: '350px'
							},
							divCard: {
								marginTop: '3px',
								padding: '7px',
								cursor: 'pointer',
								backgroundColor: 'rgb(230,230,230)',
								minHeight: '18px'
							}
						}}
					/>
				</div>
			</div>
		);
	}
}

class EditionStagePanel extends React.Component {

	refEditionAuthorityNodes = [
		{
			classId: 'AuthorityPublisher', plainFormPath: AUTHORITY_PUBLISHER_PATH, 
			selectInstanceFormId: FORM_AUTHORITY_PUBLISHER_TABLE,
			selectInstanceFormTitle: 'Авторитетні записи'
		}
	];
	
	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}

	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '0 0 0 15px', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'stage'} parentField={fieldGroup} plain={plain}
							styleSelect={{marginRight: '20px'}}
						/>
						<FieldEnum instance={instance} path={'country'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1'}}
						/>
					</div>
					<div style={{display: 'flex', marginBottom: '15px'}}>
						<FieldList_MultilangString
							parentField={fieldGroup} instance={instance} path={'area'} plain={plain}
							labelEmptyToHeader={true}
							styles={{divMain: {flexGrow: '1', marginRight: '10px'}}}
						/>
						<FieldList_MultilangString
							parentField={fieldGroup} instance={instance} path={'place'} plain={plain}
							labelEmptyToHeader={true}
							styles={{divMain: {flexGrow: '1', marginLeft: '10px'}}}
						/>
					</div>
					<div style={{display: 'flex', marginBottom: '15px'}}>
						<FieldList_MultilangString
							parentField={fieldGroup} instance={instance} path={'name'} plain={plain}
							labelEmptyToHeader={true}
							styles={{divMain: {flexGrow: '1', marginRight: '10px'}}}
						/>
						<FieldList_MultilangString
							parentField={fieldGroup} instance={instance} path={'address'} plain={plain}
							labelEmptyToHeader={true}
							styles={{divMain: {flexGrow: '1', marginLeft: '10px'}}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'year'} parentField={fieldGroup} plain={plain}
							styleInput={{marginRight: '20px'}}
						/>
						<FieldEnum instance={instance} path={'quarter'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1', marginRight: '20px'}}
						/>
						<FieldEnum instance={instance} path={'month'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1'}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldDate instance={instance} path={'plannedDate'} parentField={fieldGroup} plain={plain}
							styleInput={{marginRight: '20px', flexGrow: '1'}}
						/>
						<FieldDate instance={instance} path={'realDate'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldInteger instance={instance} path={'circulation'} parentField={fieldGroup} plain={plain}
							styleInput={{marginRight: '20px', flexGrow: '1'}}
						/>
						<FieldString instance={instance} path={'price'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						/>
					</div>
					<FieldList_MultilangString
						instance={instance} path={'comment'}  parentField={fieldGroup} plain={plain}
						labelEmptyToHeader={true}
					/>
					<FieldList_Reference path={'publisherAuthority'} instance={instance} parentField={fieldGroup} plain={plain} 
						styles={{divMain: {marginTop: '10px'}}}
						labelEmptyToHeader={true}
						refNodes={this.refEditionAuthorityNodes}
					/>
				</div>
			</div>
		)
	}
}

export default TabBrEdition;